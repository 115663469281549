import React from "react";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";

import {
  EditableImage,
  EditableMarkdown,
  OtherEventsSection,
  SponsorsSection,
} from "@app/components";
export interface GoodiebagPageData {
  hero_image: any;
  hero_body: any;
  section1_body: any;
  section1_image: any;
  section2_body: any;
  section2_image: any;
}

export const GoodiebagPage = ({
  data,
  isEdit,
}: {
  data: GoodiebagPageData;
  isEdit: boolean;
}) => {
  if (!isEdit) {
    data = (data as any).content.childMarkdownRemark.frontmatter;
  }

  return (
    <>
      {/* hero */}
      {data.hero_image && (
        <section className="d-flex justify-content-center">
          <EditableImage isEdit={isEdit} image={data.hero_image} alt="" />
        </section>
      )}

      {/* hero body */}
      {data.hero_body && (
        <section className="text-center py-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <EditableMarkdown isEdit={isEdit} markdown={data.hero_body} />
              </Col>
            </Row>
          </Container>
        </section>
      )}

      {/* section 1 */}
      {data.section1_body ||
        (data.section1_image && (
          <section className="py-5">
            <Container>
              <Row>
                <Col md={6}>
                  <EditableImage
                    isEdit={isEdit}
                    image={data.section1_image}
                    alt=""
                  />
                </Col>
                <Col md={6}>
                  <EditableMarkdown
                    isEdit={isEdit}
                    markdown={data.section1_body}
                  />
                </Col>
              </Row>
            </Container>
          </section>
        ))}

      {/* section 2 */}
      {data.section2_body ||
        (data.section2_image && (
          <section className="py-5">
            <Container>
              <Row>
                <Col md={6}>
                  <EditableMarkdown
                    isEdit={isEdit}
                    markdown={data.section2_body}
                  />
                </Col>
                <Col md={6}>
                  <EditableImage
                    isEdit={isEdit}
                    image={data.section2_image}
                    alt=""
                  />
                </Col>
              </Row>
            </Container>
          </section>
        ))}

      {/* other events */}
      {!isEdit && <OtherEventsSection />}

      {/* sponsors */}
      {!isEdit && <SponsorsSection />}
    </>
  );
};

export default GoodiebagPage;

export const query = graphql`
  query {
    content: file(
      sourceInstanceName: { eq: "content" }
      relativeDirectory: { eq: "pages" }
      name: { eq: "goodiebag" }
    ) {
      childMarkdownRemark {
        frontmatter {
          seo_title
          seo_description
          hero_image {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
          hero_body
          section1_body
          section1_image {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          section2_body
          section2_image {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
        }
      }
    }
  }
`;
